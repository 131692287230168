import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useLogout } from "react-admin";
import Popover from "@material-ui/core/Popover";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import useStyles from "./ProfileActions.styles";
import dataProvider from "data/dataProvider";
import { orgPermissions } from "utils/OrgPermissions";

import {
  Tooltip,
  ThemeProvider,
  creatorToolTheme,
} from "@my-scoot/component-library-legacy";

import classnames from "classnames";
import { is_desktop } from "utils/Utils";
import { isInternationalTimezone } from "utils/AuthUtil";
import { app_pathnames, app_route_keys } from "constants/urlPaths";

const ProfileActions = ({ width, height, color }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const logout = useLogout();
  const isOwnerOrMainUser =
    orgPermissions.isMainUser() | orgPermissions.isOwner();

  const onOpen = useCallback(
    (event) => setAnchorEl(event.currentTarget),
    [setAnchorEl]
  );
  const onClose = useCallback(() => setAnchorEl(null), [setAnchorEl]);

  return (
    <ThemeProvider theme={creatorToolTheme}>
      <Tooltip arrow color="primary" title={is_desktop ? "Account" : ""}>
        <IconButton onClick={onOpen} className={classes.profile_icon_btn}>
          <AccountCircleIcon style={{ width, height, color }} />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: classes.popover_paper,
        }}
      >
        {isOwnerOrMainUser ? (
          <>
            <MenuItem
              classes={{ root: classes.menu_link }}
              onClick={() => {
                window.location.href = `${window.location.origin}/#/myAccount`;
                onClose();
              }}
            >
              My account
            </MenuItem>
            {!isInternationalTimezone() && (
              <MenuItem
                classes={{ root: classes.menu_link }}
                onClick={() => {
                  window.location.href = `${
                    window.location.origin
                  }/#${app_pathnames[app_route_keys.my_subscriptions]()}`;
                  onClose();
                }}
              >
                My Subscriptions
              </MenuItem>
            )}
            <MenuItem
              classes={{ root: classes.menu_link }}
              onClick={() => {
                window.location.href = `${window.location.origin}/#/PayoutDetails`;
                onClose();
              }}
            >
              Bank account & Invoice
            </MenuItem>
          </>
        ) : null}

        <MenuItem
          classes={{ root: classnames(classes.menu_link, classes.log_out) }}
          onClick={async () => {
            onClose();
            dataProvider.postAnalyticalEvent("creatorPage", "signOut", {
              flow: "creatorPage",
              funnel: "creatorPage",
              ui_component: "Text Button",
              identifier: "Logout",
            });
            // eslint-disable-next-line no-restricted-globals
            if (confirm("Are you sure you want to Logout?")) {
              let analytic = await dataProvider.postAnalyticalEvent(
                "creatorPage",
                "confirmSignOut",
                {
                  flow: "creatorPage",
                  funnel: "creatorPage",
                  ui_component: "Button",
                  identifier: "Confirm",
                }
              );
              if (analytic.status === 200) {
                logout();
                orgPermissions.clear();
              }
            } else {
              dataProvider.postAnalyticalEvent("creatorPage", "cancelSignOut", {
                flow: "creatorPage",
                funnel: "creatorPage",
                ui_component: "Button",
                identifier: "Cancel",
              });
              return;
            }
          }}
        >
          Log out
        </MenuItem>
      </Popover>
    </ThemeProvider>
  );
};

export default ProfileActions;

ProfileActions.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};
