export const darkTheme = {
  palette: {
    primary: {
      main: "#90caf9",
    },
    secondary: {
      main: "#FBBA72",
    },
    type: "dark", // Switching the dark mode on is a single property value change.
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        color: "#ffffffb3",
        backgroundColor: "#616161e6",
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: "#6c5dd3",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#283593",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fff",
    },
    type: "light",
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: "275px",
  },
  overrides: {
    RaMenuItemLink: {
      root: {
        borderLeft: "3px solid #fff",
      },
      active: {
        backgroundColor: "#f0eefb",
        borderLeft: "3px solid #4f3cc9",
        color: "#6c5dd3",
        "& .sidebar-icon": {
          fill: "#6c5dd3",
          stroke: "#6c5dd3",
        },
      },
    },
    RaLoadingIndicator: {
      loadedIcon: {
        display: "none",
      },
    },
    RaFilter: {
      form: {
        marginTop: 0,
      },
    },
    RaList: {
      root: {
        "@media screen and (max-width: 780px)": {
          width: "80vw",
          overflow: "auto",
          padding: "0 0 100px 0px",
        },
      },
    },
    RaToolbar: {
      toolbar: {
        backgroundColor: "white",
      },
      mobileToolbar: {
        padding: "0 16px 0 16px",
      },
    },
    RaSidebar: {
      drawerPaper: {
        backgroundColor: "#ffffff",
        boxSizing: "content-box",
        borderRight: "1.5px solid #E7E6E4 !important",
        boxShadow: "none",
        border: "transparent !important",
        borderLeft: "none",
        borderTop: "none",
        borderBottom: "none",
      },
    },
    RaLayout: {
      content: {
        padding: "0 !important",
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "none !important",
      },
      root: {
        border: "1px solid #e0e0e3",
        backgroundClip: "padding-box",
      },
    },
    MuiButton: {
      contained: {
        backgroundColor: "#fff",
        color: "#4f3cc9",
        boxShadow: "none",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#808080",
        backgroundColor: "#fff",
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: "#f5f5f5",
      },
      barColorPrimary: {
        backgroundColor: "#d7d7d7",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        "&$disabled": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
      },
    },
    MuiLink: {
      root: {
        color: "#007bff",
      },
    },
  },
};
