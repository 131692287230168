import React from "react";
import { useTranslate } from "react-admin";
import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Collapse from "@material-ui/core/Collapse";
import useStyles from "./SubMenu.styles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import { is_desktop } from "utils/Utils";

interface Props {
  dense: boolean;
  name: string;
  id: string;
  state: any;
  toggleState: any;
}

const SubMenu: React.FC<Props> = ({
  name,
  children,
  dense,
  id,
  state,
  toggleState,
}) => {
  const translate = useTranslate();
  const open = state?.[id];
  const classes = useStyles({ open, isDesktop: is_desktop });

  const header = (
    <MenuItem
      dense={dense}
      button
      disableRipple
      onClick={() => toggleState?.(id)}
      classes={{ root: classes.listItem }}
    >
      <div>{translate(name)}</div>
      <ListItemIcon className={classes.expandIcon}>
        {!open ? <ExpandMore /> : <ExpandLess />}
      </ListItemIcon>
    </MenuItem>
  );

  return (
    <div id={id}>
      {header}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          classes={{ root: classes.collapsedList }}
          dense={dense}
          component="div"
          disablePadding
        >
          {children}
        </List>
      </Collapse>
    </div>
  );
};

export default SubMenu;
