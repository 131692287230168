import React from "react";
import { is_empty } from "../../utils/validations";
import constants from "../../constants/constants";
import Style from "../modules/mobileCardView/mobileCard.module.css";
import { DateConvert } from "../../utils/Utils";
import moment from "moment";

const DateTimeFieldCustom = ({ record = {}, source, label }) => {
  const isDesktop = window.screen.width > constants.mobile_width;
  if (is_empty(record[source]) && isDesktop) return "N/A";
  return isDesktop ? (
    <div>
      <span>
        {typeof (record[source]) === "number" ? moment.unix(record[source]).format(`MMM DD, YYYY, hh:mm${'\u00A0'}A`) : DateConvert(record, source, 'time')}
      </span>
    </div>
  ) : (
    <div className={Style.divSubContainer}>
      <span className={Style.item1}>
        <span className={Style.itemHeading}>{label}: </span>
        {typeof (record[source]) === "number" ? moment.unix(record[source]).format(`MMM DD, YYYY, hh:mm${'\u00A0'}A`) : record[source] ? <span>{DateConvert(record, source, 'time')}</span> : "N/A"}
        {"  "}
      </span>
    </div>
  );
};

export default DateTimeFieldCustom;
