import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  profile_icon_btn: {
    padding: 0,
  },
  popover_paper: {
    boxShadow: "0px 1px 4px rgba(14, 13, 12, 0.14)",
    borderRadius: "5px",
    padding: '10px 0',
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  menu_link: {
    width: '100%',
    fontSize: '16px',
    padding: "6px 16px",
    color: "#272522",
    '&:hover': {
      background: "#E7E6E4",
    },
  },
  log_out: {
    color: '#4A3AB1'
  }
});

export default useStyles;
