import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Paper, TextField } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
} from "@my-scoot/component-library-legacy";

import constants from "constants/constants";
import { isInteger, isValidEmail, is_empty } from "utils/validations";

import classnames from "classnames";
import { api, dataProvider } from "data";
import moment from "moment";
import { useNotifications } from "utils/hooks";
import { useStyles } from "./index.styles";
import { const_data } from "./data";

const EmailRecipientFieldV2 = ({
  className,
  label,
  helperText,
  error,
  inputProps,
  onChange,
  ...restProps
}) => {
  const classes = useStyles();

  const { notify } = useNotifications();

  const [recipients, setRecipients] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");

  useEffect(() => {
    (async () => {
      try {
        let recipientsData;
        let session_recipients = sessionStorage.getItem("recipients_data");
        let recipients_timestamp = sessionStorage.getItem(
          "recipients_timestamp"
        );
        let recipients_timestamp_check =
          !is_empty(recipients_timestamp) &&
          moment().diff(moment(recipients_timestamp), "minutes") > 10;

        if (is_empty(session_recipients) || recipients_timestamp_check) {
          const recipients_data = await dataProvider.custom_request(
            api.get_email_recepients
          );

          if (recipients_data.status === 200) {
            recipientsData = recipients_data.data.recipients;
            setRecipients(recipientsData);
            sessionStorage.setItem(
              "recipients_data",
              JSON.stringify(recipientsData)
            );
            sessionStorage.setItem("recipients_timestamp", moment().toString());
          }
        } else {
          recipientsData = JSON.parse(session_recipients);
          setRecipients(recipientsData);
        }
      } catch (err) {
        console.log("EmailRecipientField []", err);
        notify("Something went wrong while fetching recipients.", "error");
      }
    })();
  }, []);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 50,
  });

  const CustomPaper = React.useCallback(
    ({ children, ...restProps }) => (
      <Paper {...restProps}>
        {children}
        <Button className={classes.cta} size="thin" fullWidth>
          Apply
        </Button>
      </Paper>
    ),
    []
  );

  const getSpecificGroupsOrCustomersOptions = useCallback(
    (query) => {
      const options = recipients?.map((group) => ({
        groupBy: "Recipient Groups (Click on group to add)",
        key: group.display_name,
        value: group,
      }));

      let extras = [];

      if (query) {
        for (let item of recipients) {
          for (let email of item.emails) {
            if (
              email.name.toLowerCase().includes(query.toLowerCase()) ||
              email.email.toLowerCase().includes(query.toLowerCase())
            )
              extras.push({
                groupBy: "Recipients",
                key: email.name,
                value: {
                  key: "extra",
                  display_name: email.name,
                  emails: [
                    {
                      name: email.name,
                      email: email.email,
                    },
                  ],
                },
              });
          }
        }
      }

      if (query && isValidEmail(query))
        extras.unshift({
          key: query,
          value: {
            key: "extra",
            display_name: query,
            emails: [
              {
                name: query,
                email: query,
              },
            ],
          },
        });

      return [...extras, ...options];
    },
    [recipients]
  );

  const options = useMemo(
    () => getSpecificGroupsOrCustomersOptions(filterQuery),
    [filterQuery, recipients]
  );

  return (
    <div className={classnames(classes.root, className)}>
      {label && <div className={classes.label}>{label}</div>}

      <Autocomplete
        classes={{
          option: classes.autocompleteOption,
          groupLabel: classes.autocompleteGroupLabel,
          input: classes.input,
          inputRoot: classes.inputRoot,
          inputFocused: classes.inputFocused,
          endAdornment: classes.endAdornment,
        }}
        multiple
        options={options}
        filterOptions={filterOptions}
        PaperComponent={CustomPaper}
        disableCloseOnSelect
        groupBy={(option) => option.groupBy}
        getOptionLabel={(option) => option.key}
        popupIcon={<KeyboardArrowDownIcon />}
        inputValue={filterQuery}
        onChange={(e, value) => onChange(value)}
        renderInput={(params) => (
          <TextField
            error={error}
            variant="outlined"
            placeholder="Add Email recipents"
            onChange={({ target: { value } }) => {
              setFilterQuery(value);
            }}
            {...params}
            {...inputProps}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.key}
              label={option.key}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(option, { selected }) => {
          const { value: group } = option;
          const label = `${group.display_name.substring(
            0,
            const_data.group_max_length
          )}${
            group.display_name.length > const_data.group_max_length ? "..." : ""
          }${
            isInteger(group.listing_type)
              ? `${"\u00A0"}-${"\u00A0"}${
                  constants.schedule_type[group.listing_type].name
                }`
              : ""
          }${"\u00A0"}(${group.emails.length})`;

          return (
            <div className={classes.autoCompleteItem}>
              <Checkbox size="large" checked={selected} />
              <Avatar label={label} />
              <span className={classes.customerTitle}>{label}</span>
            </div>
          );
        }}
        {...restProps}
      />

      {(error || helperText) && (
        <div className={classnames(classes.helperText, error && classes.error)}>
          {error || helperText}
        </div>
      )}
    </div>
  );
};

export default EmailRecipientFieldV2;
