import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    label: {
      fontSize: "14px",
      fontWeight: 500,
      color: theme?.palette?.secondary?.main,
    },
    inputRoot: {
      minHeight: "40px",
      padding: "8px 52px 8px 12px !important",
      "& fieldset": {
        border: "1.5px solid rgb(207, 205, 201)",
      },
    },
    endAdornment: {
      right: "2px !important",
    },
    inputFocused: {
      "& fieldset": {
        borderColor: theme?.palette?.primary?.main,
      },
    },
    input: {
      padding: "0 !important",
    },
    autocompleteOption: {
      padding: "0 !important",
    },
    autocompleteGroupLabel: {
      color: theme?.palette?.secondary?.main,
      fontWeight: 500,
      fontSize: "14px",
      paddingLeft: "11px !important",
    },
    helperText: {
      fontSize: "11px",
    },
    error: {
      color: theme?.palette?.basic?.danger_red,
    },
    cta: {
      borderRadius: "0 0 4px 4px",
    },
    customerTitle: {
      fontSize: "14px",
      fontWeight: 400,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
    autoCompleteItem: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      gap: "12px",
      padding: "8px 0",
      marginLeft: "11px",
      borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    },
  }),
  { name: "CreatorTool" }
);
