
import React from 'react';
import constants from '../../constants/constants';
import Typography from "@material-ui/core/Typography";
import Style from "../modules/mobileCardView/mobileCard.module.css";

const ActionFieldCustom = (props) => {
  const { record = {}, source, from, label, ActionField, countryCodeSource, Nobutton, showGstHelper, fromTable, isINR } = props;
  const isDesktop = window.screen.width > constants.mobile_width;
  let displayAction = false;
  if (from === "booked" && (record.unique_customer_count > 0 || record.listing_status === 1) ||
    (from === "limitedoffers" && record.status !== 2) ||
    (from === "transactions" && record?.answer_count) ||
    (from === "affiliates" && record.bookings)
  ) {
    displayAction = true;
  } else if (from !== "booked" && from !== "limitedoffers" && from !== "transactions" && from !== "affiliates") {
    displayAction = true;
  }
  return isDesktop ? (
    <div>
      <span><ActionField {...props} record={record} emptyText={"N/A"} source={source} label={label} countryCodeSource={countryCodeSource} showGstHelper={showGstHelper} fromTable={fromTable} isINR={isINR} /></span>
    </div>
  ) : !Nobutton && displayAction ? (
    <div
      className={`${Style.itemButton}`}
    >
      <button className={`${Style.buttonContainer} ${Style.displayColor}`}>
        <Typography variant="span" component="span">
          <ActionField {...props} record={record} emptyText={"N/A"} source={source} countryCodeSource={countryCodeSource} showGstHelper={showGstHelper} fromTable={fromTable} isINR={isINR} />
        </Typography>
      </button>
    </div>
  ) : Nobutton ? (
    <div className={Style.divSubContainer}>
      <div className={`row no-gutters ${Style.item1}`}>
        {label ? <span style={{marginRight: 10}} className={Style.itemHeading}>{label}: </span> : null}
        <div style={{flexGrow: 1}}>
          <ActionField {...props} record={record} emptyText={"N/A"} source={source} countryCodeSource={countryCodeSource} showGstHelper={showGstHelper} fromTable={fromTable} isINR={isINR} />
        </div>
      </div>
    </div>
  ) : (
    null
  );
}

export default ActionFieldCustom;
