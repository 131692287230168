import makeStyles from "@material-ui/core/styles/makeStyles";

const getSelector = (className) =>
  `&[class^='${className}'], &[class*=' ${className}']`;

const useStyles = makeStyles(
  (theme) => ({
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    avatarContent: {
      paddingLeft: theme.spacing(1),
      maxWidth: 160,
      WebkitLineClamp: 1,
    },
    sidebarIsFixed: {
      position: "fixed",
      width: "inherit",
      top: "0",
      bottom: "0",
      zIndex: 101,
      overflow: "hidden",
      background: "#fff",
      marginTop: "0px !important",
      paddingTop: ({ isDesktop, showTaskPercentContainer }) => {
        const value = showTaskPercentContainer ? "72.3px" : "0px";
        const baseValue = isDesktop ? "50px" : "100px";
        return `calc(${baseValue} + ${value})`;
      },
      paddingBottom: ({ isDesktop }) => (isDesktop ? "138.88px" : "123px"),
    },
    scrollContainer: {
      height: "100%",
      overflowY: ({ open }) => (open ? "scroll" : "hidden"),
      padding: ({ isDesktop }) => (isDesktop ? "24px 0px" : "28px 0px"),
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#A09A92",
        borderRadius: "5px",
      },
    },
    headerContainer: {
      width: "100%",
      height: "100px",
      padding: "35px 9.44px 21px 100px",
      boxSizing: "border-box",
      background: "#4A3AB1",
      color: "#fff",
      position: "absolute",
      top: "0",
      fontSize: "32px",
      lineHeight: "48px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    headerCloseBtn: {
      padding: 0,
      "& svg": {
        color: "#fff",
        transform: "scale(1.21304)",
      },
    },
    taskPercentContainer: {
      position: "absolute",
      top: ({ isDesktop }) => (isDesktop ? "50px" : "100px"),
      width: "100%",
    },
    menuLink: {
      lineHeight: "20px",
      fontSize: "16px",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      justifyContent: ({ open }) => (open ? "flex-start" : "center"),
      padding: ({ open }) =>
        open ? "12px 8px 13px 24px" : "12px 0px 13px 0px",
      gap: "11px",

      color: "#6D675F",
      border: "none",

      "&:hover": {
        background: "#fff",
        color: "#6D675F",
      },

      "& .MuiListItemIcon-root": {
        minWidth: "0 !important",
      },
    },
    soloMenuItem: {
      paddingLeft: ({ open, isDesktop }) =>
        !isDesktop ? "16px" : open ? "24px" : "0px",
    },
    activeMenuLink: {
      border: "none",
      background: ({ open }) => (open ? "#fff" : "#DCD9F2"),
      color: "#4A3AB1",
      "& svg": {
        filter:
          "invert(23%) sepia(35%) saturate(4522%) hue-rotate(238deg) brightness(86%) contrast(91%)",
      },
      "&:hover": {
        background: ({ open }) => (open ? "#fff" : "#DCD9F2"),
        color: "#4A3AB1",
      },
    },
    max1Line: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1 /* number of lines to show */,
      "-webkit-box-orient": "vertical",
      maxWidth: "12em",
      textTransform: "lowercase",
    },
    linkWrapper: {
      display: "flex",
      alignItems: "center",
      marginTop: "8px",
      gap: "20px",
    },
    subdomainLink: {
      color: "#0052CC",
    },
    copyLink: {
      cursor: "pointer",
      "& svg": {
        color: "#1C1B1F",
      },
    },
    webpageAddress: {
      background: "#fff",
      width: "100%",
      height: ({ isDesktop }) => (isDesktop ? "93.88px" : "78px"),
      padding: "15px 16px 16px 16px",
      boxSizing: "border-box",
      color: "#6D675F",
      fontSize: "16px",
      fontWeight: 300,
      lineHeight: "19px",
      position: "absolute",
      bottom: "0",
    },
    innerMenuLink: {
      lineHeight: "20px",
      fontSize: "16px",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      padding: "0",
      color: "#6D675F",
      border: "none",
      minHeight: "20px",
      whiteSpace: "break-spaces",

      "&:hover": {
        background: "#fff",
        color: "#40329A",
      },

      [getSelector("exly-creator-tool--RaMenuItemLink-active")]: {
        border: "none",
        color: "#4A3AB1",
        background: "#fff",
        "&:hover": {
          background: "#fff",
          color: "#4A3AB1",
        },
      },
    },
    activeMenuItem: {
      border: "none",
      color: "#4A3AB1",
      background: "#fff",
      "&:hover": {
        background: "#fff",
        color: "#4A3AB1",
      },
    },
    helpContainer: {
      position: "absolute",
      bottom: ({ isDesktop }) => (isDesktop ? "93.88px" : "78px"),
      background: "#EEECF9",
      color: "#493AB1",
      "& svg": {
        color: "#493AB1",
      },
      fontSize: "16px",
      lineHeight: "19px",
      width: "100%",
      height: "45px",
      display: "flex",
      gap: "7px",
      padding: "0 30px 0 13px",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
      },
      "& span": {
        flexGrow: 1,
      },
    },
  }),
  { name: "CreatorTool" }
);

export default useStyles;
