import React from "react";
import { is_empty } from "../../utils/validations";
import constants from "../../constants/constants";
import { DateConvert } from "../../utils/Utils";
import Style from "../modules/mobileCardView/mobileCard.module.css";

const DateFieldCustom = ({ record = {}, source, label }) => {
  if (is_empty(record[source])) return 'N/A';
  const isDesktop = window.screen.width > constants.mobile_width;
  return isDesktop ? (
    <div>{DateConvert(record, source)}</div>
  ) : (
    <div className={Style.divSubContainer}>
      <span className={Style.item1}>
        <span className={Style.itemHeading}>{label}: </span>
        <span>{DateConvert(record, source)}</span>
      </span>
    </div>
  );
};

export default DateFieldCustom;
