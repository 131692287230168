import React from "react";
import { TextField } from "react-admin";
import constants from "../../constants/constants";
import { is_empty } from "../../utils/validations";
import Style from "../modules/mobileCardView/mobileCard.module.css";

const TextFieldCustom = ({ record = {}, source, label, NameField }) => {
  const isDesktop = window.screen.width > constants.mobile_width;

  return isDesktop ? (
    NameField ? (
      <NameField source={source} label={label} record={record} />
    ) : !is_empty(record[source]) ? (
      <TextField source={source} label={label} record={record} {...record} />
    ) : (
      "N/A"
    )
  ) : (
    <div className={Style.divSubContainer}>
      <span className={Style.item1}>
        {label ? <span className={Style.itemHeading}>{label}: </span> : null}
        {NameField ? (
          <span>
            <NameField source={source} label={label} record={record} />
          </span>
        ) : !is_empty(record[source]) ? (
          <TextField
            source={source}
            label={label}
            record={record}
            {...record}
          />
        ) : (
          "N/A"
        )}
      </span>
    </div>
  );
};

export default TextFieldCustom;
