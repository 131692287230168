import { makeStyles } from "@material-ui/core/styles";

interface IStyle {
  open: boolean;
  isDesktop: boolean;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    color: ({ open }: IStyle) => (open ? "#4A3AB1" : "#6D675F"),
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 500,
    minHeight: "20px",
    display: "flex",
    alignItems: "center",
    padding: 0,
    "&:hover": {
      background: "#fff",
    },
  },
  expandIcon: {
    height: "16px",
    "& svg": {
      height: "16px",
    },
  },
  collapsedList: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    padding: ({ isDesktop }: IStyle) =>
      isDesktop ? "20px 0 0 9.47px" : "14px 0 0 22.75px",
  },
}));

export default useStyles;
